import { graphql } from "gatsby";
import React from "react";
import ServiziPage from "../../components/pages/servizi";

const Servizi = (props) => <ServiziPage {...props} />;

export const query = graphql`
  query ServiziQuery {
    head: storyblokEntry(
      lang: { eq: "default" }
      slug: { eq: "servizi-copy" }
    ) {
      content
    }
  }
`;

export default Servizi;
